<ul class="checklist">
    <li>
        <span
            i18n="@@website.shop.school1"
        ><strong>{{ product?.discountOnFullPrice }}% extra discount</strong> for <strong>students</strong></span>
    </li>
    <li>
        <span
            i18n="@@website.shop.school2"
        ><strong>Always free</strong> for <strong>teachers</strong></span>
    </li>
    <li>
        <span
            i18n="@@website.shop.school3"
        ><strong>Free tracking platform</strong> for teachers</span>
    </li>
    <li>
        <span
            i18n="@@website.shop.school4"
        >Advanced <strong>tracking</strong></span>
    </li>
</ul>
